"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isThunk = exports.resolveThunk = void 0;
/**
 * If the input object is a thunk (function), then executes the function and returns the result.
 * If the input object is not a thunk, it is returned as is.
 *
 * @param input The object to evaluate
 * @param params Any params that need to be passed onto the thunk during execution
 */
function resolveThunk(input, ...params) {
    return isThunk(input) ? input(...params) : input;
}
exports.resolveThunk = resolveThunk;
/**
 * Checks if an object is a thunk (function).
 * @param input Object to test
 */
function isThunk(input) {
    return typeof input === 'function';
}
exports.isThunk = isThunk;
