"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SceneListQueryUpdateQueryFn = exports.SceneListQuery = void 0;
const client_1 = require("@apollo/client");
exports.SceneListQuery = client_1.gql `
	query SceneListQuery(
		$orderBy: [OrderBy!]
		$filter: SceneFilter
		$after: String
		$before: String
		$first: Int
		$last: Int
		$limit: Int
		$offset: Int
	) {
		scenes(
			orderBy: $orderBy
			filter: $filter
			after: $after
			before: $before
			first: $first
			last: $last
			limit: $limit
			offset: $offset
		) {
			edges {
				cursor
				node {
					id
					name
					description
					color
					icon
					ruleId
					entityId
				}
			}

			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}

			totalCount
		}
	}
`;
const SceneListQueryUpdateQueryFn = (previousResult, { fetchMoreResult }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    if (!fetchMoreResult) {
        return previousResult;
    }
    const prevEdges = ((_a = previousResult.scenes) === null || _a === void 0 ? void 0 : _a.edges) || [];
    const newEdges = ((_b = fetchMoreResult.scenes) === null || _b === void 0 ? void 0 : _b.edges) || [];
    return {
        // Put the new items at the end of the list and update `pageInfo`
        // so we have the new `endCursor` and `hasNextPage` values
        scenes: Object.assign(Object.assign({}, previousResult.scenes), { edges: [...prevEdges, ...newEdges], pageInfo: Object.assign(Object.assign({}, (_c = previousResult.scenes) === null || _c === void 0 ? void 0 : _c.pageInfo), { endCursor: (_e = (_d = fetchMoreResult.scenes) === null || _d === void 0 ? void 0 : _d.pageInfo) === null || _e === void 0 ? void 0 : _e.endCursor, hasNextPage: !!((_g = (_f = fetchMoreResult.scenes) === null || _f === void 0 ? void 0 : _f.pageInfo) === null || _g === void 0 ? void 0 : _g.hasNextPage), hasPreviousPage: !!((_j = (_h = fetchMoreResult.scenes) === null || _h === void 0 ? void 0 : _h.pageInfo) === null || _j === void 0 ? void 0 : _j.hasPreviousPage), startCursor: (_l = (_k = fetchMoreResult.scenes) === null || _k === void 0 ? void 0 : _k.pageInfo) === null || _l === void 0 ? void 0 : _l.startCursor }) }),
    };
};
exports.SceneListQueryUpdateQueryFn = SceneListQueryUpdateQueryFn;
