"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TraitSetParentMutation = void 0;
const client_1 = require("@apollo/client");
exports.TraitSetParentMutation = client_1.gql `
	mutation TraitSetParentMutation($id: ID!, $parentId: ID!) {
		trait {
			setParent(id: $id, parentId: $parentId) {
				id
				name
				key
				description
				status
				scope
				parentId

				attributes {
					name
					key
					description
					schema
				}
				states {
					name
					key
					description
					schema
					sync {
						operation
						direction
						auto
					}
				}
				measurements {
					name
					key
					description
					schema
					sync {
						auto
					}
				}
				commands {
					name
					key
					description
					arguments {
						name
						key
						description
						schema
					}
				}
				ui {
					type
					targetType
					targetKey
					component
				}
				rules {
					id
					name
					description
					triggers {
						name
						options
					}
					condition {
						rules
						data
					}
					actions {
						name
						options
					}
				}
			}
		}
	}
`;
