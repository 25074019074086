"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Container = void 0;
const core_1 = require("@bluebase/core");
/**
 * # Container
 *
 * Grid Container
 */
exports.Container = (0, core_1.getComponent)('Container');
