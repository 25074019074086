"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pagination = exports.PaginationDefaultProps = void 0;
const core_1 = require("@bluebase/core");
/**
 * Default props for Pagination component
 */
exports.PaginationDefaultProps = {
    count: 1,
    disabled: false,
    page: 1,
};
/**
 * 📑 The Pagination component enables the user to select a specific
 * page from a range of pages.
 *
 * ## Usage
 * ```jsx
 * <Pagination count={10} />
 * ```
 */
exports.Pagination = (0, core_1.getComponent)('Pagination');
exports.Pagination.defaultProps = exports.PaginationDefaultProps;
