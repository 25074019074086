"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlueBaseAppTestError = void 0;
const BlueBaseAppTestError = ({ progress, error: err }) => {
    const error = err || (progress && progress.error);
    if (error) {
        throw error;
    }
    return null;
};
exports.BlueBaseAppTestError = BlueBaseAppTestError;
exports.BlueBaseAppTestError.displayName = 'BlueBaseAppTestError';
