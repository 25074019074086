"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeRegistry = void 0;
const themes_1 = require("../themes");
const Registry_1 = require("./Registry");
/**
 * 🎨 ThemeRegistry
 */
class ThemeRegistry extends Registry_1.Registry {
    /**
     * Adds an Item or an Item value to the registry.
     *
     * @param item
     */
    async register(key, item) {
        return key instanceof themes_1.Theme
            ? super.register(key.key, key)
            : super.register(key, item);
    }
    /**
     * Typeguard to check a given object is an input value
     * @param value
     */
    isInputValue(value) {
        return value instanceof themes_1.Theme;
    }
    /**
     * Typeguard to check a given object is a registry item
     * @param item
     */
    isItem(item) {
        return item.value && item.value instanceof themes_1.Theme;
    }
    /**
     * Typeguard to check a given object is a input item
     * @param item
     */
    isInputItem(item) {
        return item.value && item.value instanceof themes_1.Theme;
    }
}
exports.ThemeRegistry = ThemeRegistry;
