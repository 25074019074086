"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncLinkSourceCreateMutation = void 0;
const client_1 = require("@apollo/client");
exports.SyncLinkSourceCreateMutation = client_1.gql `
	mutation SyncLinkSourceCreateMutation($input: SyncLinkSourceInput!) {
		syncLinkSource {
			create(input: $input) {
				id
				type
				key
				dataType
				entityId
				destinationId
			}
		}
	}
`;
