"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCell = exports.TableCellDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.TableCellDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🏓 TableCell
 */
exports.TableCell = (0, core_1.getComponent)('TableCell');
exports.TableCell.defaultProps = exports.TableCellDefaultProps;
