"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlueBaseModuleRegistry = void 0;
const utils_1 = require("../utils");
const Registry_1 = require("./Registry");
/**
 * A registry that has all items as promises. Used to make parts of the app capable of
 * supporting code splitting.
 */
class BlueBaseModuleRegistry extends Registry_1.Registry {
    /**
     * The set() method adds or updates an element with a specified
     * key and item to the registry.
     * @param key
     * @param value
     */
    set(key, item) {
        return super.set(key, (0, utils_1.getDefiniteModule)(item));
    }
    async register(key, item) {
        key = (0, utils_1.isPromise)(key) ? await (0, utils_1.createBlueBaseModule)(key) : (0, utils_1.getDefiniteModule)(key);
        item = (0, utils_1.isPromise)(item) ? await (0, utils_1.createBlueBaseModule)(item) : (0, utils_1.getDefiniteModule)(item);
        return super.register(key, item);
    }
    /**
     * Preload all items that have preload property set to true
     */
    async preloadAll() {
        const items = this.filter((_value, _key, item) => item.preload === true);
        const promises = Object.values(items).map((item) => item.value);
        return Promise.all(promises);
    }
    /**
     * Convert any input value to an item. This is where you transform inputs and add defualts
     * @param key
     * @param partial
     */
    createItem(key, partial) {
        const value = (0, utils_1.isBlueBaseModule)(partial.value)
            ? partial.value
            : (0, utils_1.getDefiniteBlueBaseModule)(partial.value);
        return super.createItem(key, Object.assign(Object.assign({ preload: false }, partial), { value }));
    }
    /**
     * Typeguard to check a given object is a registry value
     * @param value
     */
    isValue(value) {
        return (0, utils_1.isBlueBaseModule)(value);
    }
    /**
     * Typeguard to check a given object is an input value
     * @param value
     */
    isInputValue(value) {
        return (0, utils_1.isBlueBaseModule)(value) || !!value;
    }
}
exports.BlueBaseModuleRegistry = BlueBaseModuleRegistry;
