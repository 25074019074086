"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogActions = exports.DialogActionsDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.DialogActionsDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🔲 DialogActions
 *
 * A component to show a list of actions in a Dialog.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { Button, Dialog } from '@bluebase/components';

export default class MyComponent extends React.Component {
  state = {
    visible: false,
  };

  _hideDialog = () => this.setState({ visible: false });

  render() {
    return (
      <Dialog
        visible={this.state.visible}
        onDismiss={this._hideDialog}>
        <Dialog.Actions>
          <Button onPress={() => console.log("Cancel")}>Cancel</Button>
          <Button onPress={() => console.log("Ok")}>Ok</Button>
        </Dialog.Actions>
      </Dialog>
    );
  }
}

export default MyComponent;
```
 */
exports.DialogActions = (0, core_1.getComponent)('DialogActions');
exports.DialogActions.defaultProps = exports.DialogActionsDefaultProps;
