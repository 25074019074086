"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartCreateMutation = void 0;
const client_1 = require("@apollo/client");
exports.PartCreateMutation = client_1.gql `
	mutation PartCreateMutation($input: PartInput!) {
		part {
			create(input: $input) {
				id
				key
				name
				description
				group

				faults {
					id
					code
					severity
					name
					description
				}
			}
		}
	}
`;
