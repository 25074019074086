"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityRemoveParentMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntityRemoveParentMutation = client_1.gql `
	mutation EntityRemoveParentMutation($id: ID!) {
		entity {
			removeParent(id: $id) {
				id
				secondaryId
				name
				parentId

				parent {
					id
					name
					avatar
					thumbnail: avatar(width: 5, height: 5)
					category
					deviceTypeHandle
					parentId

					uiResolutionKeys
				}
			}
		}
	}
`;
