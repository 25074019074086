"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityUIQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntityUIQuery = client_1.gql `
	query EntityUIQuery($id: ID!, $ui: TraitUIType!) {
		entity(id: $id) {
			id
			secondaryId

			ui(types: [$ui]) {
				type
				targetType
				targetKey
				component
				definition
			}

			# attributesMap
			# stateMap
		}
	}
`;
