"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityOnAttributesMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntityOnAttributesMutation = client_1.gql `
	mutation EntityOnAttributesMutation($id: ID!, $data: OnAttributesDataInput!) {
		entity {
			onAttributes(id: $id, data: $data) {
				attributes
			}
		}
	}
`;
