"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityExecuteCommandMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntityExecuteCommandMutation = client_1.gql `
	mutation EntityExecuteCommandMutation($id: ID!, $command: String!, $params: String!) {
		entity {
			executeCommand(id: $id, command: $command, params: $params)
		}
	}
`;
