"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandQuery = void 0;
const client_1 = require("@apollo/client");
exports.BrandQuery = client_1.gql `
	query BrandQuery($id: ID!, $avatarSize: Int) {
		brand(id: $id) {
			id
			name
			handle

			avatar(width: $avatarSize, height: $avatarSize)
			thumbnail: avatar(width: 5, height: 5)
		}
	}
`;
