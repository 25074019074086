"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceTypeSetAvatarMutation = void 0;
const client_1 = require("@apollo/client");
exports.DeviceTypeSetAvatarMutation = client_1.gql `
	mutation DeviceTypeSetAvatarMutation($id: ID!, $avatarUrl: String!) {
		deviceType {
			setAvatar(id: $id, avatarUrl: $avatarUrl) {
				id
				handle
				name
				avatar
				thumbnail: avatar(width: 5, height: 5)
				category
				description
			}
		}
	}
`;
