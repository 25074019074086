"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartUpdateMutation = void 0;
const client_1 = require("@apollo/client");
exports.PartUpdateMutation = client_1.gql `
	mutation PartUpdateMutation($id: ID!, $dto: PartInput!) {
		part {
			update(id: $id, dto: $dto) {
				id
				key
				name
				description
				group

				faults {
					id
					code
					severity
					name
					description
				}
			}
		}
	}
`;
