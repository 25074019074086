"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetRegistry = void 0;
const Registry_1 = require("./Registry");
/**
 * 🖼 AssetRegistry
 */
class AssetRegistry extends Registry_1.Registry {
    /**
     * Resolves an Asset.
     * @param keys
     */
    resolve(...keys) {
        return this.findOne(...keys);
    }
    /**
     * Convert any input value to an item. This is where you transform inputs and add defualts
     * @param key
     * @param partial
     */
    createItem(key, partial) {
        // On web, require returns a string (webpack).
        // So we need to convert it to an object.
        const value = typeof partial.value === 'string' ? { uri: partial.value } : partial.value;
        return super.createItem(key, Object.assign(Object.assign({ preload: false, type: 'unknown' }, partial), { value }));
    }
    /**
     * Typeguard to check a given object is a registry value
     * @param value
     */
    isValue(value) {
        return typeof value === 'number' || (typeof value === 'object' && !!value.uri);
    }
    /**
     * Typeguard to check a given object is an input value
     * @param value
     */
    isInputValue(value) {
        return this.isValue(value) || typeof value === 'string';
    }
}
exports.AssetRegistry = AssetRegistry;
