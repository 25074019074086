"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityAttributeChangedEventSubscription = void 0;
const client_1 = require("@apollo/client");
exports.EntityAttributeChangedEventSubscription = client_1.gql `
	subscription EntityAttributeChangedEventSubscription($entityIds: [ID!]!) {
		EntityAttributeChangedEvent(entityIds: $entityIds) {
			id
			key
			value
			dataType
			entityId
			requestId
		}
	}
`;
