"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityQuery = void 0;
const client_1 = require("@apollo/client");
exports.ActivityQuery = client_1.gql `
	query ActivityQuery($id: ID!) {
		activity(id: $id) {
			id
			type
			published
			summary
			content
			actor {
				id
				type
				name
			}
			generator {
				id
				type
				name
			}
		}
	}
`;
