"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Icon = exports.IconDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.IconDefaultProps = {
    size: 24,
};
/**
 * 🗿 Icon
 */
exports.Icon = (0, core_1.getComponent)('Icon');
exports.Icon.defaultProps = exports.IconDefaultProps;
