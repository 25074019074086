"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.plugins = void 0;
exports.plugins = {
    /**
     * Registers given list of plugins
     */
    'bluebase.plugins.register': [
        {
            key: 'bluebase-plugins-register-default',
            priority: 2,
            // tslint:disable-next-line:object-literal-sort-keys
            value: async (bootOptions, _args, BB) => {
                const keys = await BB.Plugins.registerCollection(bootOptions.plugins);
                keys.forEach((key) => BB.Plugins.setMeta(key, 'source', { type: 'boot' }));
                // return
                return bootOptions;
            },
        },
    ],
};
