"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleQuery = void 0;
const client_1 = require("@apollo/client");
exports.RuleQuery = client_1.gql `
	query RuleQuery($id: ID!) {
		rule(id: $id) {
			id
			embedded
			name
			description
			active
			generator
			condition {
				rules
				data
			}
			triggers {
				name
				options
			}
			actions {
				name
				options
			}
		}
	}
`;
