"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaResolveStatesQuery = void 0;
const client_1 = require("@apollo/client");
exports.SchemaResolveStatesQuery = client_1.gql `
	query SchemaResolveStatesQuery($id: ID!) {
		schema(id: $id) {
			id

			resolveStates {
				key
				name
				description
				schema
				sync {
					auto
					operation
					direction
				}
			}
		}
	}
`;
