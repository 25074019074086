"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog = exports.DialogDefaultProps = void 0;
const core_1 = require("@bluebase/core");
const DialogActions_1 = require("./DialogActions");
const DialogContent_1 = require("./DialogContent");
const DialogTitle_1 = require("./DialogTitle");
exports.DialogDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🔲 Dialog
 *
 * Dialogs inform users about a specific task and may contain critical
 * information, require decisions, or involve multiple tasks.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { Button, Body1, Dialog, View } from '@bluebase/components';

export default class MyComponent extends React.Component {
  state = {
    visible: false,
  };

  _showDialog = () => this.setState({ visible: true });

  _hideDialog = () => this.setState({ visible: false });

  render() {
    return (
      <View>
        <Button onPress={this._showDialog}>Show Dialog</Button>
                <Dialog
                    visible={this.state.visible}
                    onDismiss={this._hideDialog}>
                    <Dialog.Title>Alert</Dialog.Title>
                    <Dialog.Content>
                        <Body1>This is simple dialog</Body1>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button onPress={this._hideDialog}>Done</Button>
                    </Dialog.Actions>
                </Dialog>
      </View>
    );
  }
}
```
 */
exports.Dialog = (0, core_1.getComponent)('Dialog');
exports.Dialog.Actions = DialogActions_1.DialogActions;
exports.Dialog.Content = DialogContent_1.DialogContent;
exports.Dialog.Title = DialogTitle_1.DialogTitle;
exports.Dialog.defaultProps = exports.DialogDefaultProps;
