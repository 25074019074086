"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioGroupDefaultProps = exports.RadioGroup = void 0;
const core_1 = require("@bluebase/core");
exports.RadioGroup = (0, core_1.getComponent)('RadioGroup');
/**
 * Default props for RadioGroup component
 */
exports.RadioGroupDefaultProps = {
    onValueChange: () => {
        return;
    },
};
