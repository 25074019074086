"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextInputDefaultProps = exports.TextInput = void 0;
const core_1 = require("@bluebase/core");
const TextInputAffix_1 = require("./TextInputAffix");
const TextInputIcon_1 = require("./TextInputIcon");
/**
 * A foundational component for inputting text into the app via a keyboard.
 * Props provide configurability for several features, such as auto-correction,
 * auto-capitalization, placeholder text, and different keyboard types, such
 * as a numeric keypad.
 */
exports.TextInput = (0, core_1.getComponent)('TextInput');
/**
 * Default props for TextInput component
 */
exports.TextInputDefaultProps = {
    autoCapitalize: 'sentences',
    autoComplete: 'on',
    autoCorrect: true,
    editable: true,
    keyboardType: 'default',
    multiline: false,
    numberOfLines: 1,
    secureTextEntry: false,
    style: {},
};
exports.TextInput.Affix = TextInputAffix_1.TextInputAffix;
exports.TextInput.Icon = TextInputIcon_1.TextInputIcon;
