"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductListQuery = void 0;
const client_1 = require("@apollo/client");
exports.ProductListQuery = client_1.gql `
	query ProductListQuery(
		$orderBy: [OrderBy!]
		$filter: ProductFilter
		$after: String
		$before: String
		$first: Int
		$last: Int
		$limit: Int
		$offset: Int
		$avatarSize: Int
	) {
		products(
			orderBy: $orderBy
			filter: $filter
			after: $after
			before: $before
			first: $first
			last: $last
			limit: $limit
			offset: $offset
		) {
			edges {
				cursor
				node {
					id
					name
					handle
					avatar(width: $avatarSize, height: $avatarSize)
					thumbnail: avatar(width: 5, height: 5)

					brand {
						id
						name
						handle
					}

					schema {
						id
						name
						handle
					}
				}
			}

			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}

			totalCount
		}
	}
`;
