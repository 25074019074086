"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncLinkDeleteMutation = void 0;
const client_1 = require("@apollo/client");
exports.SyncLinkDeleteMutation = client_1.gql `
	mutation SyncLinkDeleteMutation($id: ID!) {
		syncLink {
			delete(id: $id) {
				id
			}
		}
	}
`;
