"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandCreateMutation = void 0;
const client_1 = require("@apollo/client");
exports.BrandCreateMutation = client_1.gql `
	mutation BrandCreateMutation($input: BrandInput!) {
		brand {
			create(input: $input) {
				id
				name
				handle
			}
		}
	}
`;
