"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntityQuery = client_1.gql `
	query EntityQuery($id: ID!) {
		entity(id: $id) {
			id
			secondaryId
			name
			avatar
			thumbnail: avatar(width: 5, height: 5)
			category
			deviceTypeHandle
			parentId
			schemaId
			productId

			uiResolutionKeys

			attributesMap
			stateMap

			location: stateMap(keys: ["geolocation.latitude", "geolocation.longitude"])

			schema {
				id
				handle
				mainState
				mainCommand

				localDeviceController {
					key
					options
				}
			}

			features {
				controls
				sensors
				diagnostics
				reports
				quickCharts
			}
		}
	}
`;
