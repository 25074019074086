"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PredictionThermostatSetpointEtaQuery = void 0;
const client_1 = require("@apollo/client");
exports.PredictionThermostatSetpointEtaQuery = client_1.gql `
	query PredictionThermostatSetpointEtaQuery($id: ID!) {
		entity(id: $id) {
			id
			predictions
		}
	}
`;
