"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataObserver = void 0;
const core_1 = require("@bluebase/core");
/**
 * # ⚡️ DataObserver
 *
 * Observes data to check if it is data is loading, loaded or empty. The resulting flags
 * are passed on to the children function. These flags may be used to show different UIs,
 * i.e. loading state, empty state, etc.
 *
 * ## Usage
 * ```jsx
 * <DataObserver>
 *  <Text>{data}</Text>
 * </DataObserver>
 * ```
 */
exports.DataObserver = (0, core_1.getComponent)('DataObserver');
