"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlueBaseDefaultConfigs = void 0;
exports.BlueBaseDefaultConfigs = {
    debug: false,
    development: false,
    direction: 'auto',
    locale: 'en',
    'locale.options': {
        en: 'English',
        ur: 'اُردُو',
    },
    pluginRoutePathPrefix: 'p',
    theme: 'bluebase-theme',
    'theme.mode': 'auto',
    'theme.overrides': {
        dark: {},
        light: {},
    },
    title: 'BlueBase',
};
