"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleUpdateMutation = void 0;
const client_1 = require("@apollo/client");
exports.RuleUpdateMutation = client_1.gql `
	mutation RuleUpdateMutation($id: ID!, $dto: RuleUpdateInput!) {
		rule {
			update(id: $id, dto: $dto) {
				id
				embedded
				name
				description
				active
				generator
				condition {
					rules
					data
				}
				triggers {
					name
					options
				}
				actions {
					name
					options
				}
			}
		}
	}
`;
