"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableFooter = exports.TableFooterDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.TableFooterDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🏓 TableFooter
 *
 * Data tables allow displaying sets of data.
 *
 */
exports.TableFooter = (0, core_1.getComponent)('TableFooter');
exports.TableFooter.defaultProps = exports.TableFooterDefaultProps;
