"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityParentQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntityParentQuery = client_1.gql `
	query EntityParentQuery($id: ID!) {
		entity(id: $id) {
			id
			secondaryId
			name
			parentId

			parent {
				id
				name
				avatar
				thumbnail: avatar(width: 5, height: 5)
				category
				deviceTypeHandle
				parentId

				uiResolutionKeys
			}
		}
	}
`;
