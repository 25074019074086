"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonDefaultProps = exports.Button = void 0;
const core_1 = require("@bluebase/core");
exports.Button = (0, core_1.getComponent)('Button');
/**
 * Default props for TextInput component
 */
exports.ButtonDefaultProps = {
    color: 'primary',
    fullWidth: false,
    loading: false,
    size: 'medium',
    variant: 'contained',
};
exports.Button.defaultProps = exports.ButtonDefaultProps;
