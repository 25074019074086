"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlueBaseImage = void 0;
const core_1 = require("@bluebase/core");
// tslint:disable: jsdoc-format
/**
 * # 🖼 BlueBaseImage
 *
 * A drop in replacement for React Native's Image component.
 * Adds ability to display image stored in BlueBase Asset Registry.
 *
 * ## Usage
 * ```jsx
<BlueBaseImage resolve="Logo" />
<BlueBaseImage resolve={['LogoSquare', 'Logo']} />
```
 */
exports.BlueBaseImage = (0, core_1.getComponent)('BlueBaseImage');
