"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaQuery = void 0;
const client_1 = require("@apollo/client");
exports.SchemaQuery = client_1.gql `
	query SchemaQuery($id: ID!) {
		schema(id: $id) {
			id
			name
			handle
			description
			status
			scope

			mainState
			mainCommand

			editableAttributes
			syncableStates
			syncableMeasurements

			featureSettings {
				controls
				sensors
				diagnostics
				reports
				quickCharts
			}

			connector
			onboarding

			localDeviceController {
				key
				options
			}

			deviceType {
				id
				name
				description
				handle
			}

			products {
				id
				name
				handle
				description

				brand {
					id
					name
					handle
				}
			}

			parts {
				id
				key
				name
				description
				group

				faults {
					id
					code
					severity
					name
					description
				}
			}
		}
	}
`;
