"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntlConsumer = exports.IntlContext = void 0;
const react_1 = require("react");
/**
 * This is the context where BlueBase theme is stored.
 */
exports.IntlContext = (0, react_1.createContext)(undefined);
/**
 * BlueBase theme consumer.
 */
exports.IntlConsumer = exports.IntlContext.Consumer;
