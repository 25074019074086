"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityListQueryUpdateQueryFn = void 0;
const ActivityListQueryUpdateQueryFn = (previousResult, { fetchMoreResult }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    if (!fetchMoreResult) {
        return previousResult;
    }
    const prevEdges = (_a = previousResult.activities.edges) !== null && _a !== void 0 ? _a : [];
    const newEdges = (_b = fetchMoreResult.activities.edges) !== null && _b !== void 0 ? _b : [];
    return {
        // Put the new items at the end of the list and update `pageInfo`
        // so we have the new `endCursor` and `hasNextPage` values
        activities: Object.assign(Object.assign({}, previousResult === null || previousResult === void 0 ? void 0 : previousResult.activities), { edges: [...prevEdges, ...newEdges], pageInfo: Object.assign(Object.assign({}, (_c = previousResult === null || previousResult === void 0 ? void 0 : previousResult.activities) === null || _c === void 0 ? void 0 : _c.pageInfo), { startCursor: (_e = (_d = previousResult === null || previousResult === void 0 ? void 0 : previousResult.activities) === null || _d === void 0 ? void 0 : _d.pageInfo) === null || _e === void 0 ? void 0 : _e.startCursor, endCursor: (_g = (_f = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.activities) === null || _f === void 0 ? void 0 : _f.pageInfo) === null || _g === void 0 ? void 0 : _g.endCursor, hasNextPage: (_j = (_h = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.activities) === null || _h === void 0 ? void 0 : _h.pageInfo) === null || _j === void 0 ? void 0 : _j.hasNextPage, hasPreviousPage: (_l = (_k = previousResult === null || previousResult === void 0 ? void 0 : previousResult.activities) === null || _k === void 0 ? void 0 : _k.pageInfo) === null || _l === void 0 ? void 0 : _l.hasPreviousPage }) }),
    };
};
exports.ActivityListQueryUpdateQueryFn = ActivityListQueryUpdateQueryFn;
