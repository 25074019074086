"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaStatus = exports.SchemaScope = exports.TraitStatus = exports.TraitScope = exports.SchemaFeatureSettingOption = exports.TraitUiTargetType = exports.TraitUiType = exports.SyncLinkType = exports.TraitStateCommunicationType = exports.TraitReportType = exports.SyncLinkMeasurementDirection = exports.EntityIncidentStatus = exports.FaultSeverity = exports.SchemaConnectorType = exports.SyncLinkStateDirection = exports.DataType = exports.DeviceTypeCategory = exports.ImageResizingType = exports.OrderByNulls = exports.OrderByDirection = void 0;
var OrderByDirection;
(function (OrderByDirection) {
    OrderByDirection["Asc"] = "ASC";
    OrderByDirection["Desc"] = "DESC";
})(OrderByDirection = exports.OrderByDirection || (exports.OrderByDirection = {}));
var OrderByNulls;
(function (OrderByNulls) {
    OrderByNulls["NullsFirst"] = "NULLS_FIRST";
    OrderByNulls["NullsLast"] = "NULLS_LAST";
})(OrderByNulls = exports.OrderByNulls || (exports.OrderByNulls = {}));
var ImageResizingType;
(function (ImageResizingType) {
    ImageResizingType["Crop"] = "CROP";
    ImageResizingType["Fill"] = "FILL";
    ImageResizingType["Fit"] = "FIT";
})(ImageResizingType = exports.ImageResizingType || (exports.ImageResizingType = {}));
var DeviceTypeCategory;
(function (DeviceTypeCategory) {
    DeviceTypeCategory["Person"] = "PERSON";
    DeviceTypeCategory["Place"] = "PLACE";
    DeviceTypeCategory["Thing"] = "THING";
})(DeviceTypeCategory = exports.DeviceTypeCategory || (exports.DeviceTypeCategory = {}));
var DataType;
(function (DataType) {
    DataType["Boolean"] = "BOOLEAN";
    DataType["Date"] = "DATE";
    DataType["Number"] = "NUMBER";
    DataType["String"] = "STRING";
})(DataType = exports.DataType || (exports.DataType = {}));
var SyncLinkStateDirection;
(function (SyncLinkStateDirection) {
    SyncLinkStateDirection["Input"] = "INPUT";
    SyncLinkStateDirection["Output"] = "OUTPUT";
    SyncLinkStateDirection["TwoWay"] = "TWO_WAY";
})(SyncLinkStateDirection = exports.SyncLinkStateDirection || (exports.SyncLinkStateDirection = {}));
/** Type of Schema connector */
var SchemaConnectorType;
(function (SchemaConnectorType) {
    SchemaConnectorType["Cloud"] = "CLOUD";
    SchemaConnectorType["Direct"] = "DIRECT";
    SchemaConnectorType["Hub"] = "HUB";
    SchemaConnectorType["Loopback"] = "LOOPBACK";
})(SchemaConnectorType = exports.SchemaConnectorType || (exports.SchemaConnectorType = {}));
/** Severity level of a fault in a device */
var FaultSeverity;
(function (FaultSeverity) {
    FaultSeverity["Error"] = "ERROR";
    FaultSeverity["Warning"] = "WARNING";
})(FaultSeverity = exports.FaultSeverity || (exports.FaultSeverity = {}));
var EntityIncidentStatus;
(function (EntityIncidentStatus) {
    EntityIncidentStatus["Active"] = "ACTIVE";
    EntityIncidentStatus["Resolved"] = "RESOLVED";
    EntityIncidentStatus["Unknown"] = "UNKNOWN";
})(EntityIncidentStatus = exports.EntityIncidentStatus || (exports.EntityIncidentStatus = {}));
var SyncLinkMeasurementDirection;
(function (SyncLinkMeasurementDirection) {
    SyncLinkMeasurementDirection["Input"] = "INPUT";
    SyncLinkMeasurementDirection["Output"] = "OUTPUT";
})(SyncLinkMeasurementDirection = exports.SyncLinkMeasurementDirection || (exports.SyncLinkMeasurementDirection = {}));
/** Trait Report Type enum types */
var TraitReportType;
(function (TraitReportType) {
    TraitReportType["Embedded"] = "EMBEDDED";
    TraitReportType["Layout"] = "LAYOUT";
})(TraitReportType = exports.TraitReportType || (exports.TraitReportType = {}));
var TraitStateCommunicationType;
(function (TraitStateCommunicationType) {
    TraitStateCommunicationType["CommandOnly"] = "COMMAND_ONLY";
    TraitStateCommunicationType["QueryOnly"] = "QUERY_ONLY";
    TraitStateCommunicationType["TwoWay"] = "TWO_WAY";
})(TraitStateCommunicationType = exports.TraitStateCommunicationType || (exports.TraitStateCommunicationType = {}));
/** Type of an Entity Trait Link */
var SyncLinkType;
(function (SyncLinkType) {
    SyncLinkType["Measurement"] = "MEASUREMENT";
    SyncLinkType["State"] = "STATE";
})(SyncLinkType = exports.SyncLinkType || (exports.SyncLinkType = {}));
/** Type of UI to render for the a trait */
var TraitUiType;
(function (TraitUiType) {
    TraitUiType["Controls"] = "CONTROLS";
    TraitUiType["Custom"] = "CUSTOM";
    TraitUiType["QuickCharts"] = "QUICK_CHARTS";
    TraitUiType["Sensors"] = "SENSORS";
    TraitUiType["Settings"] = "SETTINGS";
})(TraitUiType = exports.TraitUiType || (exports.TraitUiType = {}));
/** Type of UI to render for the a trait */
var TraitUiTargetType;
(function (TraitUiTargetType) {
    TraitUiTargetType["Attribute"] = "ATTRIBUTE";
    TraitUiTargetType["Command"] = "COMMAND";
    TraitUiTargetType["Measurement"] = "MEASUREMENT";
    TraitUiTargetType["State"] = "STATE";
})(TraitUiTargetType = exports.TraitUiTargetType || (exports.TraitUiTargetType = {}));
var SchemaFeatureSettingOption;
(function (SchemaFeatureSettingOption) {
    SchemaFeatureSettingOption["Auto"] = "AUTO";
    SchemaFeatureSettingOption["Disabled"] = "DISABLED";
})(SchemaFeatureSettingOption = exports.SchemaFeatureSettingOption || (exports.SchemaFeatureSettingOption = {}));
/** Trait scope enum types */
var TraitScope;
(function (TraitScope) {
    TraitScope["Global"] = "GLOBAL";
    TraitScope["User"] = "USER";
})(TraitScope = exports.TraitScope || (exports.TraitScope = {}));
/** Status for trait */
var TraitStatus;
(function (TraitStatus) {
    TraitStatus["Draft"] = "DRAFT";
    TraitStatus["Private"] = "PRIVATE";
    TraitStatus["Public"] = "PUBLIC";
})(TraitStatus = exports.TraitStatus || (exports.TraitStatus = {}));
var SchemaScope;
(function (SchemaScope) {
    SchemaScope["Global"] = "GLOBAL";
    SchemaScope["User"] = "USER";
})(SchemaScope = exports.SchemaScope || (exports.SchemaScope = {}));
/** Status for schema */
var SchemaStatus;
(function (SchemaStatus) {
    SchemaStatus["Draft"] = "DRAFT";
    SchemaStatus["Private"] = "PRIVATE";
    SchemaStatus["Public"] = "PUBLIC";
})(SchemaStatus = exports.SchemaStatus || (exports.SchemaStatus = {}));
