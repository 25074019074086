"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityMeasurementDataDefinitionsQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntityMeasurementDataDefinitionsQuery = client_1.gql `
	query EntityMeasurementDataDefinitionsQuery($id: ID!) {
		entity(id: $id) {
			id
			secondaryId
			name

			measurementDataDefinitions {
				name
				key
				description
				schema
				sync {
					direction
					auto
				}
			}
		}
	}
`;
