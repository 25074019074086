"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawerLayout = exports.DrawerLayoutDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.DrawerLayoutDefaultProps = {
    drawerPosition: 'left',
    open: false,
};
exports.DrawerLayout = (0, core_1.getComponent)('DrawerLayout');
exports.DrawerLayout.defaultProps = exports.DrawerLayoutDefaultProps;
