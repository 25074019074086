"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SceneQuery = void 0;
const client_1 = require("@apollo/client");
exports.SceneQuery = client_1.gql `
	query SceneQuery($id: ID!) {
		scene(id: $id) {
			id
			name
			description
			color
			icon
			ruleId
			entityId

			rule {
				id
				embedded
				name
				description
				active
				condition {
					rules
					data
				}
				triggers {
					name
					options
				}
				actions {
					name
					options
				}
			}
		}
	}
`;
