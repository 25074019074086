"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardHeader = exports.CardHeaderDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.CardHeaderDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🃏 CardHeader
 *
 * A component to show a title, subtitle and an avatar inside a Card.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { Avatar, Button, Card } from '@bluebase/components';

const MyComponent = () => (
  <Card.Header
    title="Card Title"
    subtitle="Card Subtitle"
    left={<Avatar type="image" image={{ uri: 'https://picsum.photos/200' }} />}
    right={(props) => <Button title="Go" onPress={() => {}} />}
  />
);

export default MyComponent;
```
 */
exports.CardHeader = (0, core_1.getComponent)('CardHeader');
exports.CardHeader.defaultProps = exports.CardHeaderDefaultProps;
