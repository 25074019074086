"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaSetParentMutation = void 0;
const client_1 = require("@apollo/client");
exports.SchemaSetParentMutation = client_1.gql `
	mutation SchemaSetParentMutation($id: ID!, $parentId: ID!) {
		schema {
			setParent(id: $id, parentId: $parentId) {
				id
				name
				handle
				description
				status
				scope

				mainState
				mainCommand

				editableAttributes
				syncableStates
				syncableMeasurements

				featureSettings {
					controls
					sensors
					diagnostics
					reports
					quickCharts
				}

				connector
				onboarding

				localDeviceController {
					key
					options
				}

				deviceType {
					id
					name
					description
					handle
				}

				products {
					id
					name
					handle
					description

					brand {
						id
						name
						handle
					}
				}

				parts {
					id
					key
					name
					description
					group

					faults {
						id
						code
						name
						description
					}
				}
			}
		}
	}
`;
