"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaultUpdateMutation = void 0;
const client_1 = require("@apollo/client");
exports.FaultUpdateMutation = client_1.gql `
	mutation FaultUpdateMutation($id: ID!, $dto: FaultInput!) {
		fault {
			update(id: $id, dto: $dto) {
				id
				code
				severity
				name
				description
			}
		}
	}
`;
