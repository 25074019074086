"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityMetadataDeleteMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntityMetadataDeleteMutation = client_1.gql `
	mutation EntityMetadataDeleteMutation($id: ID!) {
		entityMetadata {
			delete(id: $id) {
				id
			}
		}
	}
`;
