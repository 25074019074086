"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColorScheme = void 0;
const react_native_1 = require("react-native");
const useConfig_1 = require("./useConfig");
function useColorScheme() {
    const [modeConfig] = (0, useConfig_1.useConfig)('theme.mode');
    let mode;
    try {
        const rnColorScheme = (0, react_native_1.useColorScheme)();
        mode = rnColorScheme;
        if (mode !== 'light' && mode !== 'dark') {
            mode = 'light';
        }
    }
    catch (error) {
        mode = 'light';
    }
    return modeConfig === 'auto' ? mode : modeConfig;
}
exports.useColorScheme = useColorScheme;
