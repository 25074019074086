"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardCover = exports.CardCoverDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.CardCoverDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🃏 CardCover
 *
 * A component to show a cover image inside a Card.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { Card } from '@bluebase/components';

const MyComponent = () => (
  <Card>
    <Card.Cover source={{ uri: 'https://picsum.photos/700' }} />
  </Card>
);

export default MyComponent;
```
 */
exports.CardCover = (0, core_1.getComponent)('CardCover');
exports.CardCover.defaultProps = exports.CardCoverDefaultProps;
