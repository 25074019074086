"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceTypeListQuery = void 0;
const client_1 = require("@apollo/client");
exports.DeviceTypeListQuery = client_1.gql `
	query DeviceTypeListQuery(
		$orderBy: [OrderBy!]
		$filter: DeviceTypeFilter
		$after: String
		$before: String
		$first: Int
		$last: Int
		$limit: Int
		$offset: Int
		$avatarSize: Int
	) {
		deviceTypes(
			orderBy: $orderBy
			filter: $filter
			after: $after
			before: $before
			first: $first
			last: $last
			limit: $limit
			offset: $offset
		) {
			edges {
				cursor
				node {
					id
					name
					handle
					avatar(width: $avatarSize, height: $avatarSize)
					thumbnail: avatar(width: 5, height: 5)
				}
			}

			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}

			totalCount
		}
	}
`;
