"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SceneCreateMutation = void 0;
const client_1 = require("@apollo/client");
exports.SceneCreateMutation = client_1.gql `
	mutation SceneCreateMutation($input: SceneInput!) {
		scene {
			create(input: $input) {
				id
				name
				description
				color
				icon
				ruleId
				entityId

				rule {
					id
					embedded
					name
					description
					active
					condition {
						rules
						data
					}
					triggers {
						name
						options
					}
					actions {
						name
						options
					}
				}
			}
		}
	}
`;
