"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityListQuery = void 0;
const client_1 = require("@apollo/client");
exports.ActivityListQuery = client_1.gql `
	query ActivityListQuery(
		$orderBy: [OrderBy!]
		$filter: ActivityFilter
		$after: String
		$before: String
		$first: Int
		$last: Int
		$limit: Int
		$offset: Int
	) {
		activities(
			orderBy: $orderBy
			filter: $filter
			after: $after
			before: $before
			first: $first
			last: $last
			limit: $limit
			offset: $offset
		) {
			edges {
				cursor
				node {
					id
					type
					published
					summary
					content
					actor {
						id
						type
						name
					}
					generator {
						id
						type
						name
					}
				}
			}

			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}

			totalCount
		}
	}
`;
