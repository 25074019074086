"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.joinPaths = void 0;
/**
 * Joins multiple paths
 * @param parts
 */
function joinPaths(...parts) {
    let allParts = [];
    parts.forEach((part) => {
        allParts = allParts.concat(part.split('/').filter((s) => s !== ''));
    });
    return `${allParts.join('/')}`;
}
exports.joinPaths = joinPaths;
