"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaTraitsQuery = void 0;
const client_1 = require("@apollo/client");
exports.SchemaTraitsQuery = client_1.gql `
	query SchemaTraitsQuery($id: ID!) {
		schema(id: $id) {
			id

			schemaTraits {
				id
				traitKey

				trait {
					id
					name
					key
					description
				}
			}
		}
	}
`;
