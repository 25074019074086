"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityStateQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntityStateQuery = client_1.gql `
	query EntityStateQuery($id: ID!) {
		entity(id: $id) {
			id
			secondaryId
			stateMap
		}
	}
`;
