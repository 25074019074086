"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableBody = exports.TableBodyDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.TableBodyDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🏓 TableBody
 *
 * Data tables allow displaying sets of data.
 *
 */
exports.TableBody = (0, core_1.getComponent)('TableBody');
exports.TableBody.defaultProps = exports.TableBodyDefaultProps;
