"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityTreeQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntityTreeQuery = client_1.gql `
	query EntityTreeQuery(
		$id: ID,
		$filter: EntityFilter,
		$orderBy: [OrderBy!],
		$avatarSize: Int
	) {
		entityTree(id: $id, filter: $filter, orderBy: $orderBy) {
			node {
				id
				secondaryId
				name
				avatar(width: $avatarSize, height: $avatarSize)
				thumbnail: avatar(width: 5, height: 5)
				category
				deviceTypeHandle
				parentId
				schemaId
				uiResolutionKeys
			}

			ancestors {
				id
				secondaryId
				name
				avatar(width: $avatarSize, height: $avatarSize)
				thumbnail: avatar(width: 5, height: 5)
				uiResolutionKeys
				parentId
				schemaId
			}

			children {
				id
				secondaryId
				name
				avatar(width: $avatarSize, height: $avatarSize)
				thumbnail: avatar(width: 5, height: 5)
				category
				deviceTypeHandle
				uiResolutionKeys
				parentId
				schemaId

				childrenCount
			}
		}
	}
`;
