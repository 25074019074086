"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityMetadataCreateMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntityMetadataCreateMutation = client_1.gql `
	mutation EntityMetadataCreateMutation($input: EntityMetadataInput!) {
		entityMetadata {
			create(input: $input) {
				id
				key
				name
				description
				value
				schema
				entityId
			}
		}
	}
`;
