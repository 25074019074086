"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconButton = exports.IconButtonDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.IconButtonDefaultProps = {
    disabled: false,
    size: 24,
};
/**
 * 🗿 IconButton
 *
 * An icon button is a button which displays only an icon without a label.
 */
exports.IconButton = (0, core_1.getComponent)('IconButton');
exports.IconButton.defaultProps = exports.IconButtonDefaultProps;
