"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityStateChangedEventSubscription = void 0;
const client_1 = require("@apollo/client");
exports.EntityStateChangedEventSubscription = client_1.gql `
	subscription EntityStateChangedEventSubscription($entityIds: [ID!]!) {
		EntityStateChangedEvent(entityIds: $entityIds) {
			entity {
				id
				secondaryId
				stateMap
			}
		}
	}
`;
// const updateQuery: SubscribeToMoreOptions<ActivityListQueryQuery, ActivityCreatedEventSubscriptionSubscriptionVariables, ActivityCreatedEventSubscriptionSubscription>['updateQuery'] =
// (previousQueryResult: ActivityListQueryQuery, options: {
// 	subscriptionData: {
// 			data: ActivityCreatedEventSubscriptionSubscription
// 	},
// 	variables?: ActivityCreatedEventSubscriptionSubscriptionVariables
// ): ActivityListQueryQuery => {
// 	const { subscriptionData} = options;
// 	if (!subscriptionData) {
// 		return previousQueryResult;
// 	}
// 	const prevEdges = previousQueryResult.activities.edges ?? [];
// 	const newActivity = subscriptionData.data.ActivityCreatedEvent;
// }
