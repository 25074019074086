"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Column = void 0;
const core_1 = require("@bluebase/core");
/**
 * # Column
 *
 * Grid Column
 */
exports.Column = (0, core_1.getComponent)('Column');
