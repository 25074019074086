"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tab = exports.TabDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.TabDefaultProps = {
    disabled: false,
};
/**
 * # 🗂 Tab
 * A single tab component.
 */
exports.Tab = (0, core_1.getComponent)('Tab');
exports.Tab.defaultProps = exports.TabDefaultProps;
