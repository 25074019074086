"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioDefaultProps = exports.Radio = void 0;
const core_1 = require("@bluebase/core");
exports.Radio = (0, core_1.getComponent)('Radio');
/**
 * Default props for Radio component
 */
exports.RadioDefaultProps = {
    checked: false,
    color: 'secondary',
    disabled: false,
    labelPlacement: 'end',
};
