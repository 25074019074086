"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceTypeListQueryUpdateQueryFn = void 0;
const DeviceTypeListQueryUpdateQueryFn = (previousResult, { fetchMoreResult }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    if (!fetchMoreResult) {
        return previousResult;
    }
    const prevEdges = (_b = (_a = previousResult === null || previousResult === void 0 ? void 0 : previousResult.deviceTypes) === null || _a === void 0 ? void 0 : _a.edges) !== null && _b !== void 0 ? _b : [];
    const newEdges = (_d = (_c = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.deviceTypes) === null || _c === void 0 ? void 0 : _c.edges) !== null && _d !== void 0 ? _d : [];
    return {
        // Put the new items at the end of the list and update `pageInfo`
        // so we have the new `endCursor` and `hasNextPage` values
        deviceTypes: Object.assign(Object.assign({}, previousResult === null || previousResult === void 0 ? void 0 : previousResult.deviceTypes), { edges: [...prevEdges, ...newEdges], pageInfo: Object.assign(Object.assign({}, (_e = previousResult === null || previousResult === void 0 ? void 0 : previousResult.deviceTypes) === null || _e === void 0 ? void 0 : _e.pageInfo), { startCursor: (_g = (_f = previousResult === null || previousResult === void 0 ? void 0 : previousResult.deviceTypes) === null || _f === void 0 ? void 0 : _f.pageInfo) === null || _g === void 0 ? void 0 : _g.startCursor, endCursor: (_j = (_h = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.deviceTypes) === null || _h === void 0 ? void 0 : _h.pageInfo) === null || _j === void 0 ? void 0 : _j.endCursor, hasNextPage: (_l = (_k = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.deviceTypes) === null || _k === void 0 ? void 0 : _k.pageInfo) === null || _l === void 0 ? void 0 : _l.hasNextPage, hasPreviousPage: (_o = (_m = previousResult === null || previousResult === void 0 ? void 0 : previousResult.deviceTypes) === null || _m === void 0 ? void 0 : _m.pageInfo) === null || _o === void 0 ? void 0 : _o.hasPreviousPage }) }),
    };
};
exports.DeviceTypeListQueryUpdateQueryFn = DeviceTypeListQueryUpdateQueryFn;
