"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Card = exports.CardDefaultProps = void 0;
const core_1 = require("@bluebase/core");
const CardActions_1 = require("./CardActions");
const CardContent_1 = require("./CardContent");
const CardCover_1 = require("./CardCover");
const CardHeader_1 = require("./CardHeader");
exports.CardDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🃏 Card
 *
 * Cards are surfaces that display content and actions on a single topic.
 *
 * They should be easy to scan for relevant and actionable information.
 * Elements, like text and images, should be placed on them in a way that
 * clearly indicates hierarchy.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { Avatar, Body1, Button, Card, H4 } from '@bluebase/components';

const MyComponent = () => (
  <Card>
        <Card.Header
            title="Card Title"
            subtitle="Card Subtitle"
            left={<Avatar type="image" image={{ uri: 'https://picsum.photos/200' }} />}
            right={(props) => <Button title="Go" onPress={() => {}} />}
        />
    <Card.Cover source={{ uri: 'https://picsum.photos/700' }} />
        <Card>
            <Card.Content>
                <H4>Card title</H4>
                <Body1>Card content</Body1>
            </Card.Content>
        </Card>
    <Card.Actions>
      <Button>Cancel</Button>
      <Button>Ok</Button>
    </Card.Actions>
  </Card>
);

export default MyComponent;
```
 */
exports.Card = (0, core_1.getComponent)('Card');
exports.Card.Actions = CardActions_1.CardActions;
exports.Card.Content = CardContent_1.CardContent;
exports.Card.Cover = CardCover_1.CardCover;
exports.Card.Header = CardHeader_1.CardHeader;
exports.Card.defaultProps = exports.CardDefaultProps;
