"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Link = exports.LinkDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.LinkDefaultProps = {
    method: 'navigate',
};
/**
 * 🔗 Link
 */
exports.Link = (0, core_1.getComponent)('Link');
exports.Link.defaultProps = exports.LinkDefaultProps;
