"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchbarDefaultProps = exports.Searchbar = void 0;
const core_1 = require("@bluebase/core");
exports.Searchbar = (0, core_1.getComponent)('Searchbar');
/**
 * Default props for Searchbar component
 */
exports.SearchbarDefaultProps = {
    clearAccessibilityLabel: 'clear',
    searchAccessibilityLabel: 'search',
};
