"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncLinkUpdateMutation = void 0;
const client_1 = require("@apollo/client");
exports.SyncLinkUpdateMutation = client_1.gql `
	mutation SyncLinkUpdateMutation($id: ID!, $dto: SyncLinkInput!) {
		syncLink {
			update(id: $id, dto: $dto) {
				id
				name
				type
				key
				dataType
				generator
				direction
				auto
				operation
				entityId
			}
		}
	}
`;
