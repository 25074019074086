"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductQuery = void 0;
const client_1 = require("@apollo/client");
exports.ProductQuery = client_1.gql `
	query ProductQuery($id: ID!, $avatarSize: Int) {
		product(id: $id) {
			id
			name
			handle
			description

			avatar(width: $avatarSize, height: $avatarSize)
			thumbnail: avatar(width: 5, height: 5)

			brand {
				id
				name
				handle

				avatar(width: $avatarSize, height: $avatarSize)
				thumbnail: avatar(width: 5, height: 5)
			}

			schema {
				id
				name
				handle
				connector
				onboarding

				localDeviceController {
					key
					options
				}
			}
		}
	}
`;
