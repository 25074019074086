"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncLinkSourceUpdateMutation = void 0;
const client_1 = require("@apollo/client");
exports.SyncLinkSourceUpdateMutation = client_1.gql `
	mutation SyncLinkSourceUpdateMutation($id: ID!, $dto: SyncLinkSourceInput!) {
		syncLinkSource {
			update(id: $id, dto: $dto) {
				id
				type
				key
				dataType
				entityId
				destinationId
			}
		}
	}
`;
