"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TablePagination = exports.TablePaginationDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.TablePaginationDefaultProps = {
    rowsPerPageOptions: [10, 25, 50, 100],
};
// tslint:disable: jsdoc-format
/**
 * # 🏓 TablePagination
 *
 * Data tables allow displaying sets of data.
 *
 */
exports.TablePagination = (0, core_1.getComponent)('TablePagination');
exports.TablePagination.defaultProps = exports.TablePaginationDefaultProps;
