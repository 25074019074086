"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Avatar = exports.AvatarDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.AvatarDefaultProps = {
    size: 56,
    variant: 'circle',
};
// tslint:disable: jsdoc-format
/**
 * # 🤠 Avatar
 *
 * Avatar control enables users to add images, icons or initials
 * within any application for instances such as a profile pictures.
 *
 * ## Usage
```jsx
<Avatar type="image" image={{ uri: 'https://picsum.photos/200' }} />
```
 */
exports.Avatar = (0, core_1.getComponent)('Avatar');
exports.Avatar.defaultProps = exports.AvatarDefaultProps;
