"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMobile = exports.getScreenSize = exports.getScreenSizeFromWidth = exports.BREAKPOINTS = void 0;
const react_native_1 = require("react-native");
// tslint:disable: object-literal-sort-keys
exports.BREAKPOINTS = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
};
const getScreenSizeFromWidth = (width) => {
    if (width < exports.BREAKPOINTS.xs) {
        return 'xs';
    }
    else if (width < exports.BREAKPOINTS.sm) {
        return 'sm';
    }
    else if (width < exports.BREAKPOINTS.md) {
        return 'md';
    }
    else if (width < exports.BREAKPOINTS.lg) {
        return 'lg';
    }
    return 'xl';
};
exports.getScreenSizeFromWidth = getScreenSizeFromWidth;
const getScreenSize = () => (0, exports.getScreenSizeFromWidth)(react_native_1.Dimensions.get('window').width);
exports.getScreenSize = getScreenSize;
const isMobile = () => {
    const screenSize = (0, exports.getScreenSize)();
    return screenSize === 'xs' || screenSize === 'sm';
};
exports.isMobile = isMobile;
