"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeTypeName = void 0;
function removeTypeName(input) {
    if (!input) {
        return input;
    }
    const obj = Object.assign({}, input);
    return JSON.parse(JSON.stringify(obj, (k, v) => (k === '__typename' ? undefined : v)));
}
exports.removeTypeName = removeTypeName;
