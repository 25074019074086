"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityMetadataUpdateMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntityMetadataUpdateMutation = client_1.gql `
	mutation EntityMetadataUpdateMutation($id: ID!, $dto: EntityMetadataInput!) {
		entityMetadata {
			update(id: $id, dto: $dto) {
				id
				key
				name
				description
				value
				schema
				entityId
			}
		}
	}
`;
