"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckboxDefaultProps = exports.Checkbox = void 0;
const core_1 = require("@bluebase/core");
exports.Checkbox = (0, core_1.getComponent)('Checkbox');
/**
 * Default props for Checkbox component
 */
exports.CheckboxDefaultProps = {
    checked: false,
    color: 'secondary',
    disabled: false,
    labelPlacement: 'end',
};
