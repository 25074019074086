"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = void 0;
/**
 * 📔 Logger API
 */
class Logger {
    constructor(BB) {
        this.BB = BB;
        //
    }
    log(message, ...params) {
        this.BB.Filters.run('bluebase.logger.log', message, { params });
    }
    info(message, ...params) {
        this.BB.Filters.run('bluebase.logger.info', message, { params });
    }
    warn(message, ...params) {
        this.BB.Filters.run('bluebase.logger.warn', message, { params });
    }
    error(message, ...params) {
        this.BB.Filters.run('bluebase.logger.error', message, { params });
    }
    debug(message, ...params) {
        this.BB.Filters.run('bluebase.logger.debug', message, { params });
    }
}
exports.Logger = Logger;
