"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaTraitCreateMutation = void 0;
const client_1 = require("@apollo/client");
exports.SchemaTraitCreateMutation = client_1.gql `
	mutation SchemaTraitCreateMutation($input: SchemaTraitInput!) {
		schemaTrait {
			create(input: $input) {
				id
				traitKey
				traitId
				schemaId

				trait {
					id
					name
					key
					description
				}
			}
		}
	}
`;
