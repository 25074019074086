"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwitchDefaultProps = exports.Switch = void 0;
const core_1 = require("@bluebase/core");
exports.Switch = (0, core_1.getComponent)('Switch');
/**
 * Default props for Switch component
 */
exports.SwitchDefaultProps = {
    checked: false,
    color: 'secondary',
    disabled: false,
    labelPlacement: 'start',
};
