"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Analytics = void 0;
/**
 * 📈 Analytics API
 */
class Analytics {
    constructor(BB) {
        this.BB = BB;
        //
    }
    track(data) {
        this.BB.Filters.run('bluebase.analytics.track', data);
    }
}
exports.Analytics = Analytics;
