"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPromise = exports.getDefinitePromise = void 0;
/**
 * Take an input obect. If the input is a promise, returns as is.
 * Other wise creates a promise that resolves the input.
 * @param object Input object
 */
function getDefinitePromise(object) {
    return !isPromise(object) ? Promise.resolve(object) : object;
}
exports.getDefinitePromise = getDefinitePromise;
/**
 * Checks if an object is a Promise
 * @param object Input object
 */
function isPromise(object) {
    return !!object && 'function' === typeof object.then;
}
exports.isPromise = isPromise;
