"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncLinkListQuery = void 0;
const client_1 = require("@apollo/client");
exports.SyncLinkListQuery = client_1.gql `
	query SyncLinkListQuery($id: ID!) {
		entity(id: $id) {
			id

			syncLinks {
				id
				name
				type
				key
				dataType
				operation
				generator
				direction
				auto
				entityId

				sources {
					id
					type
					key
					dataType
					generator
					auto
					entityId
					destinationId
				}
			}
		}
	}
`;
