"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TraitListQueryUpdateQueryFn = exports.TraitListQuery = void 0;
const client_1 = require("@apollo/client");
exports.TraitListQuery = client_1.gql `
	query TraitListQuery(
		$orderBy: [OrderBy!]
		$filter: TraitFilter
		$after: String
		$before: String
		$first: Int
		$last: Int
		$limit: Int
		$offset: Int
	) {
		traits(
			orderBy: $orderBy
			filter: $filter
			first: $first
			last: $last
			after: $after
			before: $before
			limit: $limit
			offset: $offset
		) {
			totalCount
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
			edges {
				cursor
				node {
					id
					name
					key
					description
					status
					scope
				}
			}
		}
	}
`;
const TraitListQueryUpdateQueryFn = (previousResult, { fetchMoreResult }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    if (!fetchMoreResult) {
        return previousResult;
    }
    const prevEdges = ((_a = previousResult.traits) === null || _a === void 0 ? void 0 : _a.edges) || [];
    const newEdges = ((_b = fetchMoreResult.traits) === null || _b === void 0 ? void 0 : _b.edges) || [];
    return {
        // Put the new items at the end of the list and update `pageInfo`
        // so we have the new `endCursor` and `hasNextPage` values
        traits: Object.assign(Object.assign({}, previousResult.traits), { edges: [...prevEdges, ...newEdges], pageInfo: Object.assign(Object.assign({}, (_c = previousResult.traits) === null || _c === void 0 ? void 0 : _c.pageInfo), { endCursor: (_e = (_d = fetchMoreResult.traits) === null || _d === void 0 ? void 0 : _d.pageInfo) === null || _e === void 0 ? void 0 : _e.endCursor, hasNextPage: !!((_g = (_f = fetchMoreResult.traits) === null || _f === void 0 ? void 0 : _f.pageInfo) === null || _g === void 0 ? void 0 : _g.hasNextPage), hasPreviousPage: !!((_j = (_h = fetchMoreResult.traits) === null || _h === void 0 ? void 0 : _h.pageInfo) === null || _j === void 0 ? void 0 : _j.hasPreviousPage), startCursor: (_l = (_k = fetchMoreResult.traits) === null || _k === void 0 ? void 0 : _k.pageInfo) === null || _l === void 0 ? void 0 : _l.startCursor }) }),
    };
};
exports.TraitListQueryUpdateQueryFn = TraitListQueryUpdateQueryFn;
