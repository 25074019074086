"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavigationActions = void 0;
const hooks_1 = require("../../hooks");
const utils_1 = require("../../utils");
const NavigationActions = ({ children }) => {
    const actions = (0, hooks_1.useNavigation)();
    return (0, utils_1.renderChildrenWithProps)(children, actions);
};
exports.NavigationActions = NavigationActions;
