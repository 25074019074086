"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuDefaultProps = exports.Menu = void 0;
const core_1 = require("@bluebase/core");
const MenuItem_1 = require("./MenuItem");
/**
 */
// tslint:disable: jsdoc-format
/**
 * # 📋 Menu
 *
 * Menus display a list of choices on temporary elevated surfaces.
 * Their placement varies based on the element that opens them.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { Button, Menu, Divider, View } from '@bluebase/components';

export default class MyComponent extends React.Component {
  state = {
    visible: false,
  };

  _openMenu = () => this.setState({ visible: true });

  _closeMenu = () => this.setState({ visible: false });

  render() {
    return (
      <View>
        <Menu
          visible={this.state.visible}
          onDismiss={this._closeMenu}
          anchor={
            <Button onPress={this._openMenu}>Show menu</Button>
          }
        >
          <Menu.Item onPress={() => {}} title="Item 1" />
          <Menu.Item onPress={() => {}} title="Item 2" />
          <Divider />
          <Menu.Item onPress={() => {}} title="Item 3" />
        </Menu>
      </View>
    );
  }
}
```
 */
exports.Menu = (0, core_1.getComponent)('Menu');
exports.Menu.Item = MenuItem_1.MenuItem;
/**
 * Default props for Menu component
 */
exports.MenuDefaultProps = {
    mode: 'default',
};
