"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceTypeQuery = void 0;
const client_1 = require("@apollo/client");
exports.DeviceTypeQuery = client_1.gql `
	query DeviceTypeQuery($id: ID!, $avatarSize: Int) {
		deviceType(id: $id) {
			id
			name
			handle

			avatar(width: $avatarSize, height: $avatarSize)
			thumbnail: avatar(width: 5, height: 5)
		}
	}
`;
