"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceTypeSetParentMutation = void 0;
const client_1 = require("@apollo/client");
exports.DeviceTypeSetParentMutation = client_1.gql `
	mutation DeviceTypeSetParentMutation($id: ID!, $parentId: ID!) {
		deviceType {
			setParent(id: $id, parentId: $parentId) {
				id
				handle
				name
				avatar
				thumbnail: avatar(width: 5, height: 5)
				category
				description
			}
		}
	}
`;
