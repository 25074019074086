"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableRow = exports.TableRowDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.TableRowDefaultProps = {
    hover: false,
    selected: false,
};
// tslint:disable: jsdoc-format
/**
 * # 🏓 TableRow
 *
 * Data tables allow displaying sets of data.
 *
 */
exports.TableRow = (0, core_1.getComponent)('TableRow');
exports.TableRow.defaultProps = exports.TableRowDefaultProps;
