"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductUpdateMutation = void 0;
const client_1 = require("@apollo/client");
exports.ProductUpdateMutation = client_1.gql `
	mutation ProductUpdateMutation($id: ID!, $dto: ProductInput!) {
		product {
			update(id: $id, dto: $dto) {
				id
				name
				handle
				description

				brand {
					id
					name
					handle
				}
			}
		}
	}
`;
