"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tabs = exports.TabsDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.TabsDefaultProps = {
    centered: false,
    indicatorColor: 'secondary',
};
// tslint:disable: jsdoc-format
/**
 * # 🗂 Tabs
 * A simple view that renders tabs.
 *
 * ## Usage
 *
 * ```jsx
<Tabs value={value} onChange={this.handleChange}>
    <Tab label="Item One" />
    <Tab label="Item Two" />
    <Tab label="Item Three" />
</Tabs>```
 */
exports.Tabs = (0, core_1.getComponent)('Tabs');
exports.Tabs.defaultProps = exports.TabsDefaultProps;
