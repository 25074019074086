"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlueBaseApp = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const BlueBase_1 = require("../../BlueBase");
const components_1 = require("../../components");
const contexts_1 = require("../../contexts");
const getComponent_1 = require("../../getComponent");
const intl_1 = require("../../intl");
const themes_1 = require("../../themes");
const BlueBaseAppError_1 = require("../BlueBaseAppError");
const BlueBaseAppLoading_1 = require("../BlueBaseAppLoading");
const BlueBaseAppTestError_1 = require("../BlueBaseAppTestError");
const useExceptionHandlingOnProduction_1 = require("./useExceptionHandlingOnProduction");
const BlueBaseContent = (0, getComponent_1.getComponent)('BlueBaseContent');
/**
 * # 🚀 BlueBaseApp
 *
 * The main BlueBase app. This is the top level component in BlueBase. Takes care
 * of initialisation, and renders either children, or app with routing.
 *
 * ## Usage
 * ```jsx
 * <BlueBaseApp BB={BB} />
 * ```
 */
const BlueBaseApp = (props) => {
    const { ErrorComponent = BlueBaseAppError_1.BlueBaseAppError, LoadingComponent = BlueBaseAppLoading_1.BlueBaseAppLoading, children, } = props;
    const [BB] = (0, react_1.useState)(props.BB || new BlueBase_1.BlueBase());
    const [bootCount, setBootCount] = (0, react_1.useState)(0);
    const [booting, setBooting] = (0, react_1.useState)(true);
    const [bootTrigger, setBootTrigger] = (0, react_1.useState)(true); // Setting to true to start boot
    const [progress, setProgress] = (0, react_1.useState)({});
    const { onError } = (0, useExceptionHandlingOnProduction_1.useExceptionHandlingOnProduction)(BB);
    async function boot(reset) {
        if (!booting) {
            setBooting(true);
        }
        await BB.boot(Object.assign(Object.assign({}, props), { reset, onProgress: (p) => {
                setProgress(p);
                if (p.error) {
                    setBooting(false);
                }
            } }));
        setBootCount(bootCount + 1);
        setBooting(false);
    }
    BB.reboot = async (reset = false) => {
        boot(reset);
    };
    (0, react_1.useEffect)(() => {
        if (bootTrigger) {
            setBootTrigger(false);
        }
        else {
            return;
        }
        boot();
    }, [bootTrigger]);
    if (booting) {
        return <LoadingComponent BB={BB} progress={progress} bootCount={bootCount}/>;
    }
    if (progress.error) {
        return <ErrorComponent BB={BB} progress={progress} bootCount={bootCount}/>;
    }
    return (<contexts_1.BlueBaseProvider key={`boot-${bootCount}`} value={BB}>
			<components_1.ErrorObserver errorComponent={ErrorComponent} BB={BB} progress={progress} bootCount={bootCount} onError={onError}>
				<themes_1.ThemeProvider>
					<intl_1.IntlProvider>
						<BlueBaseContent>{children}</BlueBaseContent>
					</intl_1.IntlProvider>
				</themes_1.ThemeProvider>
			</components_1.ErrorObserver>
		</contexts_1.BlueBaseProvider>);
};
exports.BlueBaseApp = BlueBaseApp;
const defaultProps = {
    ErrorComponent: process.env.NODE_ENV === 'test' ? BlueBaseAppTestError_1.BlueBaseAppTestError : BlueBaseAppError_1.BlueBaseAppError,
    LoadingComponent: BlueBaseAppLoading_1.BlueBaseAppLoading,
};
exports.BlueBaseApp.defaultProps = defaultProps;
exports.BlueBaseApp.displayName = 'BlueBaseApp';
