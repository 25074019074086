"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextInputAffix = exports.TextInputAffixDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.TextInputAffixDefaultProps = {
// size: 24,
};
/**
 * 📚 TextInputAffix
 *
 * A component to text inside a TextInput.
 */
exports.TextInputAffix = (0, core_1.getComponent)('TextInputAffix');
exports.TextInputAffix.defaultProps = exports.TextInputAffixDefaultProps;
