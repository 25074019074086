"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlueBaseImageBackground = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const BlueBaseImage_1 = require("../../components/BlueBaseImage");
const contexts_1 = require("../../contexts");
const hooks_1 = require("../../hooks");
const BlueBaseImageBackground = (props) => {
    const { source: _source } = props, rest = tslib_1.__rest(props, ["source"]);
    const BB = (0, react_1.useContext)(contexts_1.BlueBaseContext);
    const Theme = (0, hooks_1.useTheme)();
    const source = (0, BlueBaseImage_1.resolveImageSource)(props, BB, Theme);
    if (!source) {
        return props.children;
    }
    return react_1.default.createElement(react_native_1.ImageBackground, Object.assign(Object.assign({}, rest), { source }));
};
exports.BlueBaseImageBackground = BlueBaseImageBackground;
