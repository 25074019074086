"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeConsumer = exports.ThemeContext = void 0;
const react_1 = require("react");
/**
 * This is the context where BlueBase theme is stored.
 */
exports.ThemeContext = (0, react_1.createContext)(undefined);
/**
 * BlueBase theme consumer.
 */
exports.ThemeConsumer = exports.ThemeContext.Consumer;
