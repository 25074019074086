"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaResolveMeasurementsQuery = void 0;
const client_1 = require("@apollo/client");
exports.SchemaResolveMeasurementsQuery = client_1.gql `
	query SchemaResolveMeasurementsQuery($id: ID!) {
		schema(id: $id) {
			id

			resolveMeasurements {
				key
				name
				description
				schema
				sync {
					auto
				}
			}
		}
	}
`;
