"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableHead = exports.TableHeadDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.TableHeadDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🏓 TableHead
 *
 * Data tables allow displaying sets of data.
 *
 */
exports.TableHead = (0, core_1.getComponent)('TableHead');
exports.TableHead.defaultProps = exports.TableHeadDefaultProps;
