"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TraitSetAvatarMutation = void 0;
const client_1 = require("@apollo/client");
exports.TraitSetAvatarMutation = client_1.gql `
	mutation TraitSetAvatarMutation($id: ID!, $avatarUrl: String!) {
		trait {
			setAvatar(id: $id, avatarUrl: $avatarUrl) {
				id
				name
				key
				description
				status
				scope
				parentId

				attributes {
					name
					key
					description
					schema
				}
				states {
					name
					key
					description
					schema
					sync {
						operation
						direction
						auto
					}
				}
				measurements {
					name
					key
					description
					schema
					sync {
						auto
					}
				}
				commands {
					name
					key
					description
					arguments {
						name
						key
						description
						schema
					}
				}
				ui {
					type
					targetType
					targetKey
					component
				}
				rules {
					id
					name
					description
					triggers {
						name
						options
					}
					condition {
						rules
						data
					}
					actions {
						name
						options
					}
				}
			}
		}
	}
`;
