"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityReportsQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntityReportsQuery = client_1.gql `
	query EntityReportsQuery($id: ID!) {
		entity(id: $id) {
			id
			secondaryId

			reports {
				key
				name
				description
				icon {
					type
					component
					name
					size
					source
				}
				type
				layout
				url
			}
		}
	}
`;
