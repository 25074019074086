"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandUpdateMutation = void 0;
const client_1 = require("@apollo/client");
exports.BrandUpdateMutation = client_1.gql `
	mutation BrandUpdateMutation($id: ID!, $dto: BrandInput!) {
		brand {
			update(id: $id, dto: $dto) {
				id
				name
				handle
			}
		}
	}
`;
