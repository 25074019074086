"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityOnStateMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntityOnStateMutation = client_1.gql `
	mutation EntityOnStateMutation($id: ID!, $data: OnStateDataInput!) {
		entity {
			onState(id: $id, data: $data) {
				time
			}
		}
	}
`;
