"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityMetadataListQueryUpdateQueryFn = void 0;
const EntityMetadataListQueryUpdateQueryFn = (previousResult, { fetchMoreResult }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    if (!fetchMoreResult) {
        return previousResult;
    }
    const prevEdges = (_a = previousResult.entity.metadata.edges) !== null && _a !== void 0 ? _a : [];
    const newEdges = (_b = fetchMoreResult.entity.metadata.edges) !== null && _b !== void 0 ? _b : [];
    return {
        // Put the new items at the end of the list and update `pageInfo`
        // so we have the new `endCursor` and `hasNextPage` values
        entity: Object.assign(Object.assign({}, previousResult === null || previousResult === void 0 ? void 0 : previousResult.entity), { metadata: Object.assign(Object.assign({}, previousResult === null || previousResult === void 0 ? void 0 : previousResult.entity.metadata), { edges: [...prevEdges, ...newEdges], pageInfo: Object.assign(Object.assign({}, (_c = previousResult === null || previousResult === void 0 ? void 0 : previousResult.entity) === null || _c === void 0 ? void 0 : _c.metadata.pageInfo), { startCursor: (_e = (_d = previousResult === null || previousResult === void 0 ? void 0 : previousResult.entity) === null || _d === void 0 ? void 0 : _d.metadata.pageInfo) === null || _e === void 0 ? void 0 : _e.startCursor, endCursor: (_g = (_f = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.entity) === null || _f === void 0 ? void 0 : _f.metadata.pageInfo) === null || _g === void 0 ? void 0 : _g.endCursor, hasNextPage: (_j = (_h = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.entity) === null || _h === void 0 ? void 0 : _h.metadata.pageInfo) === null || _j === void 0 ? void 0 : _j.hasNextPage, hasPreviousPage: (_l = (_k = previousResult === null || previousResult === void 0 ? void 0 : previousResult.entity) === null || _k === void 0 ? void 0 : _k.metadata.pageInfo) === null || _l === void 0 ? void 0 : _l.hasPreviousPage }) }) }),
    };
};
exports.EntityMetadataListQueryUpdateQueryFn = EntityMetadataListQueryUpdateQueryFn;
