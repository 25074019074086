"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncLinkQuery = void 0;
const client_1 = require("@apollo/client");
exports.SyncLinkQuery = client_1.gql `
	query SyncLinkQuery($id: ID!) {
		syncLink(id: $id) {
			id
			name
			type
			key
			dataType
			operation
			generator
			direction
			auto
			entityId
			entity {
				id
				name
				avatar(width: 100, height: 100)
			}

			sources {
				id
				type
				key
				dataType
				generator
				auto
				destinationId
				entityId
				entity {
					id
					name
					avatar(width: 100, height: 100)
				}
			}
		}
	}
`;
