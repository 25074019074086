"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavigationContext = exports.StubNavigationActionsObject = void 0;
const react_1 = require("react");
const stubAction = () => {
    return;
};
exports.StubNavigationActionsObject = {
    route: { key: 'Stub', params: {}, name: 'Stub' },
    navigate: stubAction,
    reset: stubAction,
    goBack: stubAction,
    isFocused: stubAction,
    canGoBack: stubAction,
    getId: stubAction,
    getParent: stubAction,
    setParams: stubAction,
    setOptions: stubAction,
    replace: stubAction,
    push: stubAction,
    pop: stubAction,
    popToTop: stubAction,
    openDrawer: stubAction,
    closeDrawer: stubAction,
    toggleDrawer: stubAction,
    jumpTo: stubAction,
    source: null,
};
exports.NavigationContext = (0, react_1.createContext)(exports.StubNavigationActionsObject);
