"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConfig = void 0;
const react_1 = require("react");
const useBlueBase_1 = require("./useBlueBase");
function useConfig(key, callback, deps = []) {
    const BB = (0, useBlueBase_1.useBlueBase)();
    const [value, setConfig] = (0, react_1.useState)(BB.Configs.getValue(key));
    const setValue = (v) => {
        BB.Configs.setValue(key, v);
        BB.Configs.setMeta(key, 'mutated', true);
    };
    (0, react_1.useEffect)(() => {
        let cancelled = false;
        // Subscribe
        const subscriptionId = BB.Configs.subscribe(key, (v, item) => {
            if (cancelled) {
                BB.Configs.unsubscribe(key, subscriptionId);
                return;
            }
            setConfig(v);
            if (callback) {
                callback(v, item, cancelled);
            }
        });
        // Unsubscribe from config updates
        return () => {
            cancelled = true;
            BB.Configs.unsubscribe(key, subscriptionId);
        };
    }, [value, ...deps]);
    return [value, setValue];
}
exports.useConfig = useConfig;
