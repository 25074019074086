"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Row = void 0;
const core_1 = require("@bluebase/core");
/**
 * # Row
 *
 * Grid Row
 */
exports.Row = (0, core_1.getComponent)('Row');
