"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityMetadataListQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntityMetadataListQuery = client_1.gql `
	query EntityMetadataListQuery(
		$id: ID!
		$orderBy: [OrderBy!]
		$filter: EntityMetadataFilter
		$after: String
		$before: String
		$first: Int
		$limit: Int
		$offset: Int
		$last: Int
	) {
		entity(id: $id) {
			id
			secondaryId
			name

			metadata(
				orderBy: $orderBy
				filter: $filter
				after: $after
				before: $before
				first: $first
				last: $last
				limit: $limit
				offset: $offset
			) {
				totalCount

				pageInfo {
					startCursor
					endCursor
					hasNextPage
					hasPreviousPage
				}

				edges {
					cursor
					node {
						id
						key
						name
						description
						value
						schema
						entityId
					}
				}
			}
		}
	}
`;
