"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorObserver = void 0;
const core_1 = require("@bluebase/core");
/**
 * # 🚨 ErrorObserver
 *
 * Observes any exceptions in child tree hierarchy. When an exception is caught, displays
 * an Error state to gracefully handle it on the frontend.
 *
 * ## Usage
 * ```jsx
 * <ErrorObserver
 *  errorComponent={ErrorState}
 * >
 *  <Text>Rendered if there is no error here</Text>
 * </ErrorObserver>
 * ```
 */
exports.ErrorObserver = (0, core_1.getComponent)('ErrorObserver');
