"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BottomNavigationAction = exports.BottomNavigationActionDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.BottomNavigationActionDefaultProps = {};
/**
 * # 🗂 BottomNavigationAction
 * A single tab component in the Bottom Navigation.
 */
exports.BottomNavigationAction = (0, core_1.getComponent)('BottomNavigationAction');
exports.BottomNavigationAction.defaultProps = exports.BottomNavigationActionDefaultProps;
