"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlueBaseFilter = void 0;
const core_1 = require("@bluebase/core");
/**
 * # 🚇 BlueBaseFilter
 *
 * Since filters in BlueBase are based on promises, it may be tedious to handle loading state,
 * error state, etc. It may also become a repetitive task.
 *
 * To solve this issue, we ship BlueBaseFilter component. Just pass name of filter, initial value,
 * and filter arguments as props. The final filtered value will be passed to the children function.
 * This component will handle loading and error states itself.
 *
 * ## Usage
 * ```jsx
 * <BlueBaseFilter filter="math" value={5} args={{ op: 'add' }} children={(val: number) => {
 * 	return <Text>{val}</Text>;
 * }} />
 * ```
 */
exports.BlueBaseFilter = (0, core_1.getComponent)('BlueBaseFilter');
