"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleCreateMutation = void 0;
const client_1 = require("@apollo/client");
exports.RuleCreateMutation = client_1.gql `
	mutation RuleCreateMutation($input: RuleInput!) {
		rule {
			create(input: $input) {
				id
				embedded
				name
				description
				active
				generator
				condition {
					rules
					data
				}
				triggers {
					name
					options
				}
				actions {
					name
					options
				}
			}
		}
	}
`;
