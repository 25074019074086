"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardContent = exports.CardContentDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.CardContentDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🃏 CardContent
 *
 * A component to show content inside a Card.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { Body1, Card, H4 } from '@bluebase/components';

const MyComponent = () => (
  <Card>
    <Card.Content>
      <H4>Card title</H4>
      <Body1>Card content</Body1>
    </Card.Content>
  </Card>
);

export default MyComponent;
```
 */
exports.CardContent = (0, core_1.getComponent)('CardContent');
exports.CardContent.defaultProps = exports.CardContentDefaultProps;
