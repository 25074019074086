"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextInputIcon = exports.TextInputIconDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.TextInputIconDefaultProps = {
// size: 24,
};
/**
 * 📚 TextInputIcon
 *
 * A component to show tiles inside a TextInput.
 */
exports.TextInputIcon = (0, core_1.getComponent)('TextInputIcon');
exports.TextInputIcon.defaultProps = exports.TextInputIconDefaultProps;
