"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filters = void 0;
exports.filters = {
    'bluebase.filters.register': [
        /**
         * Registers all filters in the bluebase.ts
         */
        {
            key: 'bluebase-filters-register-default',
            priority: 3,
            value: async (bootOptions, _ctx, BB) => {
                await BB.Filters.registerNestedCollection(bootOptions.filters);
                return bootOptions;
            },
        },
        /**
         * Registers all filters in the plugins
         */
        {
            key: 'bluebase-filters-register-from-plugins',
            priority: 4,
            value: async (bootOptions, _ctx, BB) => {
                const plugins = await BB.Plugins.getAllEnabled();
                for (const plugin of plugins) {
                    await BB.Filters.registerNestedCollection(plugin.filters);
                }
                return bootOptions;
            },
        },
    ],
};
