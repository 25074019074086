"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntitySyncableDataDefinitionsQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntitySyncableDataDefinitionsQuery = client_1.gql `
	query EntitySyncableDataDefinitionsQuery($id: ID!, $filterUsedKeys: Boolean) {
		entity(id: $id) {
			id
			secondaryId
			name

			syncableDataDefinitions(filterUsedKeys: $filterUsedKeys) {
				states {
					name
					key
					description
					schema
					sync {
						operation
						direction
						auto
					}
				}

				measurements {
					name
					key
					description
					schema
					sync {
						auto
					}
				}
			}
		}
	}
`;
