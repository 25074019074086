"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListItem = exports.ListItemDefaultProps = void 0;
const core_1 = require("@bluebase/core");
exports.ListItemDefaultProps = {
    disabled: false,
    inset: false,
    selected: false,
    titleNumberOfLines: 1,
    descriptionNumberOfLines: 2,
};
/**
 * 📚 ListItem
 *
 * A component to show tiles inside a List.
 */
exports.ListItem = (0, core_1.getComponent)('ListItem');
exports.ListItem.defaultProps = exports.ListItemDefaultProps;
